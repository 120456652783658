.mapboxgl-map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.control-panel {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 500px;
    margin: 20px;
    font-size: 13px;
    line-height: 2;
}